import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { has } from 'lodash';
import qs from 'qs';
import toppic from './assets/img/header.jpg';
import banner from './assets/img/banner.jpg';
import { isBrowser, isMobile } from 'react-device-detect';

function App() {
  const questions = [
    {
      q: 'Vad heter damernas Svenska Cupen-pokal?',
      opt: [
        'Svenska Spels pokal',
        'Susanne Erlandssons pokal',
        'Drottning Silvias pokal',
      ],
    },
    {
      q: 'Vilket herrlag har vunnit Svenska Cupen flest gånger?',
      opt: ['Malmö FF', 'AIK', 'IFK Göteborg'],
    },
    {
      q: 'Vilket damlag vann Svenska Cupen 2021?',
      opt: ['Inget', 'FC Rosengård', 'BK Häcken'],
    },
    {
      q: 'När spelades den första cupfinalen för herrar?',
      opt: ['1941', '1943', '1981'],
    },
    {
      q: 'Vilken match vill du ha biljetter till?',
      opt: [
        'Damfinalen (spelas i Göteborg)',
        'Herrfinalen (spelas i Malmö)',
      ],
    },
  ];
  const [answers, setAnswers] = useState([false, false, false, false, false]);
  const [motivation, setMotivation] = useState('');
  const [isQuesDone, setIsQuesDone] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [check, setCheck] = useState(false);
  const [showThx, setShowThx] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [allDone, setAllDone] = useState(false);

  useEffect(() => {
    let done = true;
    let aDone = true;
    answers.forEach((item, index) => {
      if (!item && index < 4) {
        done = false;
      }
      if (!item) {
        aDone = false;
      }
    });
    if (!done) {
      setMotivation('');
    }
    setIsQuesDone(done);
    setAllDone(aDone);
  }, [answers]);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const sendData = () => {
    setLoading(true);
    let data = {
      data: {
        name: name,
        phone: phone,
        email: email,
        ques1: answers[0],
        ques2: answers[1],
        ques3: answers[2],
        ques4: answers[3],
        want: answers[4],
        motiv: motivation,
      },
    };

    let h = 'cuppen.se';
    let host_endpoint = '/api/';
    let endpoint = 'save';
    let host = window.location.hostname;
    if (host === 'localhost') {
      host = h;
    }
    host = window.location.protocol + '//' + host + host_endpoint;

    return axios
      .post(host + endpoint, qs.stringify(data, { parseArrays: false }))
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setShowThx(true);
          setAnswers([false, false, false, false, false]);
          setName('');
          setPhone('');
          setEmail('');
          setMotivation('');
          setCheck(false);
          setAllDone(false);
          setIsQuesDone(false);
          setTimeout(() => {
            setShowThx(false);
          }, 5000);
        }
      })
      .catch((err) => {
        setLoading(false);

        return Promise.reject(err.response);
      });
  };
  return (
    <div className={isMobile ? 'App mobile' : 'App'}>
      <div className="cornerHolder">
        <div className={'corner'} />
      </div>
      <div className="toppic"></div>
      <div className="block n1">
        <div className="info">
          <h1>
            Oddset ger dig chansen att vinna exklusiva VIP-biljetter till herr- eller damfinalen i Svenska Cupen 1 maj 2024. Häcken-Piteå och Malmö FF-Djurgården drabbar samman på Bravida Arena respektive Eleda Stadion. Tävla nu – du och din vän får ett minne för livet!
          </h1>
          {/*<span>
            Oddset har haft en tävling där priset varit biljetter till herr- och
            damfinalen i Svenska Cupen.
          </span>*/}
          
          {/*<br /><span className="header">Vinnarna till herrfinalen:</span>

          <p>
            1:a pris: Peter Nässén.
            <br />
            2:a pris: Stefan Winberg, Emil Lindma, David Alkemark, Jonatan
            Lundén.
          </p>

          <span className="header">Vinnarna till damfinalen:</span>
          <p>
            1:a pris: Helena Lorentzon.
            <br />
            2:a pris: Johanna Wiesel, Michael Olausson, Ingela Wallin, Daniel
            von Zweigbergk.
          </p>
*/}
          <a
            href="./regler_och_villkor_svenska_cupen_finalen.pdf"
            target="_blank"
            className="header yellow"
          > 
            Fullständiga regler och villkor hittar du här
          </a>
          <span className="header">Så här tävlar du:</span>
          <p>
            1. Svara på frågorna nedan.
            <br />
            2. Skriv en motivering varför just ni ska vinna.
          </p>

          <span className="header">Vinst:</span>
          <p>
            <i>1:a pris:</i>
            <br />
            Två VIP-biljetter till någon av finalerna.
            <br />
          </p>

          <p>
            <i>2:a pris:</i>
            <br />
            Två vanliga biljetter till någon av finalerna.
          </p>
          <span className="header">Tävlingen:</span>
          <div className="competitionHolder">
            <div className="competition">
              {questions.map((item, index) => {
                if (index === 4) {
                  return;
                }
                return (
                  <div key={'q' + index} className="question">
                    <span className="header">{item.q}</span>
                    {item.opt.map((q, ind) => {
                      let start = '1: ';
                      if (ind === 1) {
                        start = 'X: ';
                      } else if (ind === 2) {
                        start = '2: ';
                      }
                      return (
                        <p
                          key={'qa' + index + '_' + ind}
                          className={
                            answers[index] !== false &&
                            answers[index] === start + q
                              ? 'selected'
                              : ''
                          }
                          onClick={() => {
                            let a = [...answers];
                            a[index] = start + q;
                            setAnswers(a);
                          }}
                        >
                          {start + q}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
                    <div className="mot" style={!isQuesDone ? { opacity: 0.5 } : {}}>
                <span className="header">
                  Motivera varför just du och din vän ska vinna: (Max 500
                  tecken)
                </span>

                <textarea
                  disabled={!isQuesDone}
                  onChange={(val) => {
                    if (val.target.value.length <= 500) {
                      setMotivation(val.target.value);
                    }
                  }}
                  value={motivation}
                />
                <span className="counter">( {motivation.length} )</span>
                {questions.map((item, index) => {
                  if (index < 4) {
                    return;
                  }
                  return (
                    <div key={'q' + index} className="question">
                      <span className="header">{item.q}</span>
                      {item.opt.map((q, ind) => {
                        return (
                          <p
                            key={'qa' + index + '_' + ind}
                            className={
                              answers[index] !== false && answers[index] === q
                                ? 'selected'
                                : ''
                            }
                            onClick={() => {
                              if (isQuesDone) {
                                let a = [...answers];
                                a[index] = q;
                                setAnswers(a);
                              }
                            }}
                          >
                            {q}
                          </p>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {!showThx && (
            <div
              className="form"
              style={allDone && motivation.length > 5 ? {} : { opacity: 0.5 }}
            >
              <div className="field">
                <span className="label">Namn:</span>
                <input
                  type="text"
                  value={name}
                  name="name"
                  autoComplete="name"
                  disabled={!allDone && motivation.length < 5}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="field">
                <span className="label">Mejladress:</span>
                <input
                  type="text"
                  name="email"
                  autocomplete="email"
                  disabled={!allDone && motivation.length < 5}
                  value={email}
                  style={
                    email.length > 4 && !validateEmail(email)
                      ? { color: 'red' }
                      : {}
                  }
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="field">
                <span className="label">Telefonnummer:</span>
                <input
                  disabled={!allDone && motivation.length < 5}
                  type="text"
                  name="tel"
                  autocomplete="phone"
                  value={phone}
                  onChange={(e) => {
                    var phoneRe = /^[29]\d{2}[29]\d{2}\d{4}$/;
                    var digits = e.target.value.replace(/\D/g, '');

                    setPhone(digits);
                  }}
                />
              </div>
              <div className="field fieldtex">
                <p>
                  Jag godkänner{' '}
                  <a
                    className="yellow"
                    href="./regler_och_villkor_svenska_cupen_finalen.pdf"
                    target="_blank"
                  >
                    regler och villkor
                  </a>
                  <label className="cholder">
                    <input
                      disabled={!allDone && motivation.length < 5}
                      type="checkbox"
                      onClick={(e) => {
                        setCheck(e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </p>
                <div
                  className="submit"
                  onClick={() => {
                    if (
                      allDone &&
                      motivation.length > 5 &&
                      name.length > 0 &&
                      phone.length > 5 &&
                      check &&
                      email.length > 3 &&
                      !loading &&
                      validateEmail(email)
                    ) {
                      sendData();
                    }
                  }}
                  style={
                    allDone &&
                    motivation.length > 5 &&
                    name.length > 0 &&
                    phone.length > 5 &&
                    !loading &&
                    check &&
                    email.length > 3 &&
                    validateEmail(email)
                      ? { opacity: 1 }
                      : {}
                  }
                >
                  SKICKA
                </div>
              </div>
            </div>
          )}
          {showThx && (
            <div
              className="form"
              style={{
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <h1 style={{ marginBottom: 0, fontSize: '2rem' }}>Tack!</h1>
              <br />
              <p style={{ margin: 0 }}>Du är nu med och deltar i tävlingen.</p>
            </div>
          )}
        </div>
      </div>
      <div className="block n2">
        <a
          href="https://spela.svenskaspel.se/odds/sports/fotboll"
          target="_blank"
        >
          <img src={banner} />
        </a>
        <p>
          Spel från Svenska Spel Sport & Casino.
          <br />
          Åldersgräns 18år. {'  '}
          <a href="https://stodlinjen.se/" target="_blank">
            Stödlinjen.se
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
